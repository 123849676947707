import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as TYPES from "../../../constants/actionTypes";
import { ScaleTag } from "../../../components/ScaleTag";
import icon_group from "../../../assets/icon-group.svg";
import { v4 as uuidv4 } from "uuid";
import { SCALE_TAG_STATE } from "../../../interfaces/types";
import { ScalePage } from "../../Scale/ScalePage";
import { ImportScaleKeysModal } from "../../../components/ImportScaleKeysModal";
import { singleEffectiveGroupSelector } from "../../../selectors/groups";
import { TreeNode } from "../../../reducers/groups";

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  padding: 1rem;
  flex: 1;
  max-width: 50%;
  background-color: var(--ice-blue);
  border-radius: 4px;
`;

const ContentSection = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
`;
const ContentBox = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 3px;
`;

const ContentKeyBox = styled.div`
  display: grid;
  grid-template-rows: 1fr 10fr 15fr;
  grid-template-columns: 1fr;
  grid-gap: 3px;
`;
const MainGroupKeyBox = styled.div`
  height: 100%;
  border-radius: 4px;
  border: solid 1px var(--light-blue-grey);
  background-color: #ffffff;
  margin-right: 32px;
  overflow-y: scroll;
  max-height: 300px;
`;

const OtherGroupKeyBox = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 10fr;
  grid-template-columns: 5fr 5fr;
  grid-gap: 1px;
`;

interface TextProps {
  fontSize?: string;
  marginLeft?: string;
  marginTop?: string;
  marginBottom?: string;
  fontWeight?: string;
  textAling?: string;
}
const Text = styled.div<TextProps>`
  font-family: Poppins;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  text-align: ${(props) => (props.textAling ? props.textAling : "left")};
`;
interface BoxProps {
  backgroundColor?: string;
  border?: string;
  minWidth?: string;
  alignItems?: string;
  justifyContent?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  overflowY?: boolean;
  maxHeight?: string;
}

const Box = styled.div<BoxProps>`
    display: flex;
    align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
    justify-content: ${(props) =>
      props.justifyContent ? props.justifyContent : "center"};
    flex-direction: column;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")}
    width: ${(props) => (props.width ? props.width : "100%")};
    border-radius: 4px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "var(--ice-blue)"};
    border: ${(props) => (props.border ? props.border : "none")};
    overflow-y: ${(props) => (props.overflowY ? "scroll" : "initial")};
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "none")};
`;

const Icon = styled.img`
  object-fit: contain;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  min-height: 96px;
  border-radius: 50%;
  background-color: #fefefe;
  margin-bottom: 27px;
`;

const Input = styled.input`
  min-width: 289px;
  min-height: 24px;
  border-radius: 12px;
  background-color: #ffffff;
  border-style: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  &:focus {
    border: solid 2px #4e9bf9;
    outline: none;
  }
  ::placeholder {
    font-family: "Poppins", "Europress";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  margin-top: 24px;
`;

const ImportKeysButton = styled.button`
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  display: inline;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 41px 35px 0 35px;
`;

const Heading = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

interface ScaleKeysProps {
  tags: Array<any>;
  group: TreeNode | null;
  token: string;
  addTag(tag: string, token: string, group: any): void;
  removeTag(tag: string, group: any, token: string): void;
  fetchGroupTags(group: any, token: string): void;
  importKeys(rows: string[], group: any, token: string): void;
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    addTag: (tag: string, token: string, group: any) => {
      dispatch({
        type: TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG,
        payload: {
          tag: tag,
          group: group.guid,
          token: token,
        },
      });
    },
    removeTag: (tag: string, group: any, token: string) => {
      dispatch({
        type: TYPES.ADMIN_REMOVE_SCALE_TAG,
        payload: {
          tag: tag,
          guid: group.guid,
          token: token,
        },
      });
    },
    fetchGroupTags: (group: any, token: string) => {
      dispatch({
        type: TYPES.FETCH_ADMIN_GROUP_SCALE_TAGS,
        payload: {
          group: group.guid,
          token: token,
        },
      });
    },
    importKeys: (rows: string[], group: any, token: string) => {
      dispatch({
        type: TYPES.ADMIN_IMPORT_SCALE_TAGS,
        payload: {
          group: group.guid,
          rows: rows,
          token: token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    tags: state.scale.adminTags,
    group: singleEffectiveGroupSelector(state),
    token: state.token.key,
  };
};

class ScaleKeysConnected extends Component<ScaleKeysProps, any> {
  private _input: any = undefined;
  constructor(props: ScaleKeysProps) {
    super(props);
    this._input = React.createRef();
    this.state = {
      tagsAdded: 0,
      keysImportModalOpen: false,
    };
  }
  private inputChanged = (e: any) => {
    if (e.target.value.length >= 8 && this.props.group) {
      this.props.addTag(
        String(e.target.value),
        this.props.token,
        this.props.group
      );
      this.setState({ tagsAdded: this.state.tagsAdded + 1 });
      e.target.value = "";
    }
  };
  private handleTagRemove = (
    tag: string,
    _tag_status: SCALE_TAG_STATE
  ): void => {
    if (this.props.group) {
      this.props.removeTag(tag, this.props.group, this.props.token);
    }
  };
  private handleOpenModal = () => {
    this.setState({
      ...this.state,
      keysImportModalOpen: true,
    });
  };
  private handleCloseModal = () => {
    this.setState({
      ...this.state,
      keysImportModalOpen: false,
    });
  };
  private handleImportKeys = (rows: string[]) => {
    if (this.props.group) {
      this.props.importKeys(rows, this.props.group, this.props.token);
    }
  };
  public componentDidMount(): void {
    if (this.props.group && this.props.token) {
      this.props.fetchGroupTags(this.props.group, this.props.token);
    }
    if (this._input && this.props.group!.groupType !== 1) {
      this._input.current.focus();
    }
  }
  public componentDidUpdate(prevProps: ScaleKeysProps): void {
    if (
      this.props.group &&
      this.props.token &&
      (!prevProps.group || prevProps.group.groupId !== this.props.group.groupId)
    ) {
      this.props.fetchGroupTags(this.props.group, this.props.token);
    }
  }
  public render(): JSX.Element {
    const placeHolder = "Key ID";
    let groupName = "-";
    let groupType = 0;
    if (this.props.group) {
      groupName = this.props.group.name;
      groupType = this.props.group.groupType || 0;
    }
    const activeTags = [];
    const childTags = [];
    const parentTags = [];
    for (let i = 0; i < this.props.tags.length; ++i) {
      const tag = this.props.tags[i];
      if (tag.group_type === 1) {
        activeTags.push(tag);
      } else if (tag.group_type === 2) {
        childTags.push(tag);
      } else if (tag.group_type === 3) {
        parentTags.push(tag);
      }
    }
    return (
      <>
        <ScalePage>
          {groupType === 1 ? (
            <InfoBox>
              <div>This group is an operator group.</div>
            </InfoBox>
          ) : (
            <>
              <HeadingWrapper>
                <Heading>Add keys to group</Heading>
                <ImportKeysButton onClick={this.handleOpenModal}>
                  Import keys
                </ImportKeysButton>
              </HeadingWrapper>
              <ImportScaleKeysModal
                modalOpen={this.state.keysImportModalOpen}
                handleCloseModal={this.handleCloseModal}
                handleSubmit={this.handleImportKeys}
                groupName={groupName}
              />
              <ContentSection>
                <ContentBox>
                  <Box marginLeft={"32px"} width={"92%"} maxHeight={"658px"}>
                    <Circle>
                      <Icon src={icon_group} />
                    </Circle>
                    <Text fontSize={"14px"}>Read code* to add key to</Text>
                    <Text
                      fontSize={"18px"}
                      marginTop={"8px"}
                      fontWeight={"bold"}
                      textAling={"center"}
                    >
                      {groupName}
                    </Text>
                    <div>
                      <Input
                        type="text"
                        ref={this._input}
                        placeholder={placeHolder}
                        onChange={this.inputChanged}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = placeHolder)}
                      ></Input>
                    </div>
                    <Text marginTop={"37px"} fontSize={"14px"}>
                      Keys added on this session: {this.state.tagsAdded}
                    </Text>
                  </Box>
                  <Text
                    fontSize={"12px"}
                    marginLeft={"32px"}
                    marginTop={"-200px"}
                  >
                    *Alternatively, you can manually input the key ID to the
                    text field above.
                  </Text>
                </ContentBox>
                <ContentBox>
                  <Text
                    fontSize={"14px"}
                    marginTop={"10px"}
                    marginBottom={"8px"}
                    fontWeight={"bold"}
                  >
                    Keys in group {groupName}: {activeTags.length}
                  </Text>

                  <ContentKeyBox>
                    <Text fontSize={"12px"}>
                      These keys can use all devices in {groupName} or any
                      subgroup.
                    </Text>
                    <MainGroupKeyBox>
                      <ul
                        style={{
                          overflow: "auto",
                          listStyleType: "none",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {activeTags
                          .sort((a, b) => (a.alias > b.alias ? 1 : -1))
                          .map((key) => (
                            <li key={uuidv4()}>
                              <ScaleTag
                                tag={
                                  key.alias
                                    ? `${key.alias}, ${key.tag_id}`
                                    : key.tag_id
                                }
                                tag_id={key.tag_id}
                                tag_status={key.tag_status}
                                tag_owner={
                                  key.group_name ? key.group_name : groupName
                                }
                                operation={"Remove"}
                                onOperation={this.handleTagRemove}
                              />
                            </li>
                          ))}
                      </ul>
                    </MainGroupKeyBox>
                    <OtherGroupKeyBox>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        marginTop={"20px"}
                      >
                        Keys in parent groups:{" "}
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        marginTop={"20px"}
                      >
                        Keys in subgroups:
                      </Text>
                      <Text fontSize={"12px"}>
                        These keys can also use {groupName} devices.
                      </Text>
                      <Text fontSize={"12px"}>
                        These keys can only use devices in the subgroup.
                      </Text>
                      <Box
                        backgroundColor={"var(--ice-blue)"}
                        border={"solid 1px var(--light-blue-grey)"}
                        alignItems={"normal"}
                        justifyContent={"normal"}
                        width={"91%"}
                        overflowY={false}
                        maxHeight={"200px"}
                      >
                        <ul
                          style={{
                            overflow: "auto",
                            listStyleType: "none",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {parentTags.map((key) => (
                            <li key={uuidv4()}>
                              <ScaleTag
                                tag={
                                  key.alias
                                    ? `${key.alias}, ${key.tag_id}`
                                    : key.tag_id
                                }
                                tag_id={key.tag_id}
                                tag_status={key.tag_status}
                                tag_owner={
                                  key.group_name ? key.group_name : groupName
                                }
                                operation={undefined}
                                onOperation={this.handleTagRemove}
                              />
                            </li>
                          ))}
                        </ul>
                      </Box>
                      <Box
                        backgroundColor={"var(--ice-blue)"}
                        border={"solid 1px var(--light-blue-grey)"}
                        alignItems={"normal"}
                        justifyContent={"normal"}
                        marginRight={"32px"}
                        width={"91%"}
                        overflowY={false}
                        maxHeight={"200px"}
                      >
                        <ul
                          style={{
                            overflow: "auto",
                            listStyleType: "none",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {childTags.map((key) => (
                            <li key={uuidv4()}>
                              <ScaleTag
                                tag={
                                  key.alias
                                    ? `${key.alias}, ${key.tag_id}`
                                    : key.tag_id
                                }
                                tag_id={key.tag_id}
                                tag_status={key.tag_status}
                                tag_owner={
                                  key.group_name ? key.group_name : groupName
                                }
                                operation={undefined}
                                onOperation={this.handleTagRemove}
                              />
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </OtherGroupKeyBox>
                  </ContentKeyBox>
                </ContentBox>
              </ContentSection>
            </>
          )}
        </ScalePage>
      </>
    );
  }
}
export const ScaleKeys = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScaleKeysConnected);
